import styled from "styled-components";
import { CompCardDetail } from "../../../store/model/actorGroupModel";
import { MetricToImperialString } from "../../../utility/measurement";

import Table from "react-bootstrap/Table";
import ImdbIcon from "../../../assets/components/ImdbIcon";
import YoutubeIcon from "../../../assets/components/YoutubeIcon";
import Link from "../../../assets/components/Link";
import DocumentIcon from "../../../assets/components/DocumentIcon";
import config from "../../../config";

const MainTable = styled(Table)`
    width: 100%;
    height: 100%;
    table-layout: fixed;
    margin-bottom: 0;

    & > tbody > tr > td:first-child {
        background: #eee;
        width: 45%;
        font-weight: bold;
    }

    td {
        word-wrap: break-word;
        padding-top: 0;
        padding-bottom: 0;
    }
`;

interface CompCardDetailTableProps {
    actor: CompCardDetail;
    isPrinterFriendly?: boolean;
}

const CompCardDetailTable: React.FC<CompCardDetailTableProps> = ({
    actor,
    isPrinterFriendly = false,
}) => {

    return (
        <MainTable
            bordered
            style={{
                fontSize: isPrinterFriendly ? "0.9rem" : "0.8rem",
                lineHeight: "16px",
            }}
        >
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>{actor.fullName}</td>
                </tr>
                <tr>
                    <td>Date of Birth</td>
                    <td>
                        {new Date(actor.dateOfBirth).toLocaleDateString(
                            "en-AU"
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Height</td>
                    <td>
                        {actor.height
                            ? MetricToImperialString(actor.height, false, true)
                            : ""}
                    </td>
                </tr>
                <tr>
                    <td>Eye Colour</td>
                    <td>{actor.eyeColour}</td>
                </tr>
                <tr>
                    <td>Hair Colour</td>
                    <td>{actor.hairColour}</td>
                </tr>
                <tr>
                    <td>Nationality</td>
                    <td>{actor.nationality}</td>
                </tr>
                <tr>
                    <td>
                        Comments                    
                    </td>
                    <td style={{ padding: "0" }}>
                        <Table
                            style={{
                                fontSize: isPrinterFriendly
                                    ? "0.8rem"
                                    : "0.7rem",
                                marginBottom: "0",
                            }}
                        >
                            <tbody>    
                                {actor.fluentIn &&
                                    <tr>
                                        <td>{`Fluent In: ${actor.fluentIn.join(", ")}`}</td>
                                    </tr>
                                }                          
                                
                                <tr>
                                    <td
                                        style={{
                                            textDecoration: "underline",
                                        }}
                                    >{`Size: ${
                                        actor.bust ? "C:" + actor.bust + "'" : ""
                                    } W:${actor.waist + "'"} ${
                                        actor.hips && actor.hips > 0 ? "H: " + actor.hips + "'" : ""
                                    }`}</td>
                                </tr>
                                <tr>
                                    <td>{`${
                                        actor.dressSize
                                            ? "D: " +
                                              actor.dressSize.sizeStart +
                                              "/" +
                                              actor.dressSize.sizeEnd +
                                              " "
                                            : ""
                                    }Shoe: ${actor.shoeSize}`}</td>
                                </tr>                                
                                <tr>
                                    <td>{`Profession: ${actor.professions && actor.professions.length > 0 ? actor.professions[0] : ""}`}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
                {!isPrinterFriendly && ((actor.links && actor.links.length > 0) || actor.actorResumeId) &&
                    <tr>
                    <td>
                        Links
                    </td>
                    <td>
                        <div className="d-flex justify-content-evenly p-1">
                        {actor.actorResumeId &&
                            <a href={config.api.BASE_URL + "/actor/resume/" + actor.actorId}><DocumentIcon style={{ height: "36px"}} fill="#000"/></a>
                        }
                        {actor.links.map(x => {
                                switch(x.linkTitle.toLowerCase()){
                                    case "youtube":
                                        return <a href={x.link}><YoutubeIcon style={{ width: "1.8rem", height: "2rem"}} /></a>                                            
                                    case "imdb": 
                                        return <a href={x.link}><ImdbIcon style={{ width: "1.8rem", height: "2rem"}} /></a>                                            
                                    default:
                                        return <a href={x.link}><Link style={{ width: "1.8rem", height: "2rem"}} /></a>
                                }
                            })}
                        </div>
                    </td>
                </tr>
                }
                
            </tbody>
        </MainTable>
    );
};

export default CompCardDetailTable;
